import { Observable, OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

export function transformDataToDecimals(obj: any, fields: DecimalField[] = []) {
    if (Array.isArray(obj)) {
        return obj.map((innerObj) =>
            transformObjectWithDecimals(innerObj, fields)
        );
    }

    return transformObjectWithDecimals(obj, fields);
}

export function transformObjectWithDecimals(obj: any, fields: DecimalField[]) {
    for (const field of fields) {
        if (!obj.hasOwnProperty(field.path)) {
            continue;
        }
        if (field.type === 'array' && Array.isArray(obj[field.path])) {
            obj[field.path] = transformDataToDecimals(
                obj[field.path],
                field.fields
            );
        } else if (field.type === 'child') {
            obj[field.path] = transformObjectWithDecimals(
                obj[field.path],
                field.fields
            );
        } else if (typeof obj[field.path] === 'string') {
            if (obj[field.path] === 'NaN') {
                obj[field.path] = null;
            } else if (field.type === 'float') {
                obj[field.path] = parseFloat(obj[field.path]);
            } else if (field.type === 'int') {
                obj[field.path] = parseInt(obj[field.path], 10);
            }
        }
    }

    return obj;
}

export function transformDecimals<T>(
    fields: DecimalField[]
): OperatorFunction<any, T> {
    return (requestObs: Observable<any>) =>
        requestObs.pipe(
            map((obj) => transformDataToDecimals(obj, fields) as T)
        );
}

export interface DecimalField {
    path: string;
    type: 'float' | 'int' | 'array' | 'child';
    fields?: DecimalField[];
}

export function transformObjectDecimalsToFloats<T>(): OperatorFunction<any, T> {
    return (requestObs: Observable<any>) =>
        requestObs.pipe(map((obj) => parseObjectDecimalsToFloats(obj) as T));
}

export function parseObjectDecimalsToFloats(obj: any) {
    if (Array.isArray(obj)) {
        return obj.map((item) => parseDecimalsToFloats(item));
    }

    return parseDecimalsToFloats(obj);
}

export function parseDecimalsToFloats<T>(obj: any) {
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    for (const field in obj) {
        if (!obj.hasOwnProperty(field)) {
            continue;
        }
        if (Array.isArray(obj[field])) {
            obj[field] = transformDataToDecimals(obj[field]);
        } else if (typeof obj[field] === 'object') {
            obj[field] = parseDecimalsToFloats(obj[field]);
        }
        else if (typeof obj[field] === 'string') {
            // TODO: determine if we want to do this at all, currently we do not apply this
            //       logic to zip codes because it will remove any leading zeroes
            if (field !== 'zipCode' && field !== 'postalCode') {
                if (obj[field] === 'NaN') {
                    obj[field] = null;
                } else if (obj[field] !== '') {
                    const parsedValue = Number(obj[field]);
                    obj[field] = Number.isNaN(parsedValue)
                        ? obj[field]
                        : parsedValue;
                }
            }
        }
    }

    return obj;
}
