import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DrawerComponent } from './drawer/drawer.component';
import { DrawerDirective } from './drawer/drawer.directive';
import { DrawerAppendToDirective } from './drawer/drawer-append-to.directive';

@NgModule({
    imports: [CommonModule, FontAwesomeModule],
    declarations: [
        DrawerComponent,
        DrawerDirective,
        DrawerAppendToDirective,
    ],
    exports: [DrawerDirective, DrawerAppendToDirective],
})
export class CoreModule {}
