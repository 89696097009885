import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FirstLetterPipe } from './first-letter.pipe';
import { SafePipe } from './safe.pipe';
import { UserRolePipe } from './user-role.pipe';

@NgModule({
    declarations: [
        FirstLetterPipe,
        SafePipe,
        UserRolePipe
    ],
    imports: [CommonModule],
    exports: [
        FirstLetterPipe,
        SafePipe,
        UserRolePipe
    ]
})
export class CustomPipesModule {}
