import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    FormControl,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
    distinctUntilChanged,
    map,
    takeUntil,
    startWith,
    tap,
} from 'rxjs/operators';

import * as moment from 'moment';

import { PlanRates, RateType } from '../../../interfaces';
import { normalizeEffectiveDate, normalizeTerminationDate } from '@benefit-sculptor/core';
import { RatesService } from '../../services/rates.service';
import { AgeRateCurve } from '../../models/create-plans.models';
import { AgeRateForm, AgeRateFormValues, CompositeForm, getAgeRateFormControls, RatesDetailsForm, RatesForm } from '../../models/RatesForm.model';

@Component({
    selector: 'besc-rates',
    templateUrl: './rates.component.html',
    styleUrls: ['./rates.component.scss'],
    providers: [RatesService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RatesComponent implements OnInit, OnDestroy {
    private _onDestroy$ = new Subject();

    @Input() rates: PlanRates;
    @Input() employerZipCode: string;
    @Input() effectiveDate: string;
    @Input() expirationDate: string;
    @Input() overrideEffectiveDate: string;

    ratesForm = new FormGroup<RatesForm>({
        effectiveDate: new FormControl(
            normalizeEffectiveDate(moment().startOf('year')),
            Validators.required,
        ),
        expirationDate: new FormControl(
            normalizeTerminationDate(moment().endOf('year')),
            Validators.required,
        ),
        rates: new FormGroup<RatesDetailsForm>({
            composite: new FormGroup<CompositeForm>({
                single: new FormControl(null, Validators.required),
                singleAndChildren: new FormControl(null, Validators.required),
                singleAndSpouse: new FormControl(null, Validators.required),
                family: new FormControl(null, Validators.required),
            }),
            age: new FormGroup<AgeRateForm>(getAgeRateFormControls(), { validators: [this._ageRatesValidator(false)] }),
            tobacco: new FormGroup<AgeRateForm>(getAgeRateFormControls(), { validators: [this._ageRatesValidator(true)] }),
            type: new FormControl(RateType.Composite),
        }),
        age21Rate: new FormControl(0, [Validators.required, Validators.min(0.1)]),
        hasTobaccoRates: new FormControl(false),
        age21TobaccoRate: new FormControl(0, [Validators.required, Validators.min(0.1)]),
        id: new FormControl(null),
    });

    private readonly _numberOfAges = 66;
    ages = Array.from(Array(this._numberOfAges).keys());
    ageCurve$: Observable<AgeRateCurve>;
    ageCurveLoading$ = new BehaviorSubject<boolean>(true);

    hideRates$ = new BehaviorSubject<boolean>(true);

    minMaxDates$ = this.ratesForm.valueChanges
        .pipe(
            startWith(this.ratesForm.getRawValue()),
            map((rateForm) => {
                const effectiveDate = moment(
                    this.effectiveDate,
                    moment.HTML5_FMT.DATE
                );
                const expirationDate = moment(
                    this.expirationDate,
                    moment.HTML5_FMT.DATE
                );
                return {
                    effective: {
                        min: effectiveDate,
                        max: expirationDate,
                    },
                    expiration: {
                        min: rateForm.effectiveDate
                            ? moment(rateForm.effectiveDate, moment.HTML5_FMT.DATE).clone().add(1, 'month')
                            : moment(effectiveDate, moment.HTML5_FMT.DATE).clone().add(1, 'month'),
                    },
                };
            })
        );

    isFormValidBasedOnRateType$ = new BehaviorSubject<boolean>(false);

    animateCalculateRates$ = new BehaviorSubject<boolean>(false);

    constructor(
        private _ratesService: RatesService
    ) {}

    ngOnInit() {
        this.getAgeRateCurve();
        this.setFormInitialValue();
        this.watchAgeRateValueChanges();
        this.watchRateTypeValueChanges();
        this.watchEffectiveDateValueChanges();
        this.watchTerminationDateValueChanges();
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
        this._onDestroy$.complete();
    }

    getAgeRateCurve(): void {
       this.ageCurve$ = this._ratesService.getAgeRateCurve(this.employerZipCode).pipe(
        tap(() => this.ageCurveLoading$.next(false))
       );
    }

    setFormInitialValue(): void {
        this.ratesForm.patchValue({
            effectiveDate: this.rates ? moment(this.rates.effectiveDate, moment.HTML5_FMT.DATE) : moment(this.effectiveDate, moment.HTML5_FMT.DATE),
            expirationDate: this.rates 
                ? moment(this.rates.expirationDate, moment.HTML5_FMT.DATE)
                : normalizeTerminationDate(moment(this.effectiveDate, moment.HTML5_FMT.DATE).clone().add(11, 'months')),
            rates: this.rates ? this.rates.rates : {
                type: RateType.Composite,
                age: {},
                tobacco: {},
                composite: {
                    single: null,
                    singleAndChildren: null,
                    singleAndSpouse: null,
                    family: null
                }
            },
            age21Rate: this.rates ? this.rates.rates.age.age21 : null,
            age21TobaccoRate: this.rates ? this.rates.rates.tobacco.age21 : null,
            hasTobaccoRates: this.rates ? this.rates.rates.tobacco.age21 > 0 : null,
            id: this.rates ? this.rates.id : null
        });
        if (this.overrideEffectiveDate) {
            this.ratesForm.patchValue({
                effectiveDate: moment(this.overrideEffectiveDate, moment.HTML5_FMT.DATE),
                expirationDate: normalizeTerminationDate(moment(this.overrideEffectiveDate, moment.HTML5_FMT.DATE).clone().add(11, 'months'))
            });
        }
        if (this.rates && this.rates.rates.type === RateType.Composite) {
            this.ratesForm.get('rates.composite').markAsTouched();
            this.isFormValidBasedOnRateType$.next(this._isCompositeValid());
        }
        if (this.rates && this.rates.rates.type === RateType.AgeRate) {
            this.ratesForm.get('rates.age').markAsTouched();
            this.ratesForm.get('age21Rate').markAsTouched();
            if (this.rates.rates.tobacco.age21 > 0) {
                this.ratesForm.get('rates.tobacco').markAsTouched();
            }
            this.isFormValidBasedOnRateType$.next(this._isAgeRatesValid())
        }
    }

    watchEffectiveDateValueChanges(): void {
        this.ratesForm.controls.effectiveDate.valueChanges
            .pipe(
                tap((value: moment.Moment) => this.ratesForm.controls.expirationDate.patchValue(
                    normalizeTerminationDate(moment(value, moment.HTML5_FMT.DATE).clone().add(11, 'months'))
                )),
                takeUntil(this._onDestroy$),
            )
            .subscribe();
    }

    watchTerminationDateValueChanges(): void {
        this.ratesForm.controls.expirationDate.valueChanges
            .pipe(
                tap((value: moment.Moment) => this.ratesForm.controls.expirationDate.patchValue(
                    normalizeTerminationDate(moment(value, moment.HTML5_FMT.DATE).clone()), { emitEvent: false }
                )),
                takeUntil(this._onDestroy$),
            )
            .subscribe();
    }

    watchRateTypeValueChanges(): void {
        this.ratesForm
            .valueChanges.pipe(
                distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
                tap(values => values.rates.type === RateType.Composite 
                    ? this.isFormValidBasedOnRateType$.next(this._isCompositeValid())
                    : this.isFormValidBasedOnRateType$.next(this._isAgeRatesValid())),
                takeUntil(this._onDestroy$)
            )
            .subscribe();
    }

    watchAgeRateValueChanges(): void {
        this.ratesForm.controls.age21Rate.valueChanges
            .pipe(
                tap(() => this.ratesForm.get('rates.age').markAsUntouched()),
                tap(() => this.animateCalculateRates$.next(this._shouldCalculateRatesBeAnimated())),
                takeUntil(this._onDestroy$),
            )
        .subscribe();

        this.ratesForm.controls.age21TobaccoRate.valueChanges
        .pipe(
            tap(() => this.ratesForm.get('rates.tobacco').markAsUntouched()),
            tap(() => this.animateCalculateRates$.next(this._shouldCalculateRatesBeAnimated())),
            takeUntil(this._onDestroy$),
        )
        .subscribe();
    }

    calculateRates(ageRateCurve: AgeRateCurve): void {
        const age21Rate = this.ratesForm.get('age21Rate').value;
        const age21TobaccoRate = this.ratesForm.get('age21TobaccoRate').value;
        const hasTobaccoRates = this.ratesForm.get('hasTobaccoRates').value;
        const ageRates = {} as AgeRateFormValues;
        const tobacco = {} as AgeRateFormValues;

        ageRateCurve.ageRateCurve.forEach((ageRate, i) => {
            ageRates['age' + i] = Number(Math.round(parseFloat(ageRate * age21Rate + 'e2')) + 'e-2');
            if (hasTobaccoRates) {
                tobacco['age' + i] = Number(Math.round(parseFloat(ageRate * age21TobaccoRate + 'e2')) + 'e-2');
            }
        })

        this.ratesForm.get('rates.age').markAsTouched();
        this.ratesForm.get('rates.age').setValue(ageRates);

        if (hasTobaccoRates) {
            this.ratesForm.get('rates.tobacco').markAsTouched();
            this.ratesForm.get('rates.tobacco').setValue(tobacco);
        }
    }

    toggleRatesTable(): void {
        this.hideRates$.next(!this.hideRates$.getValue());
    }

    private _isCompositeValid(): boolean {
        return this.ratesForm.controls.effectiveDate.valid
            && this.ratesForm.controls.expirationDate.valid
            && this.ratesForm.get('rates.composite').valid
            && this.ratesForm.get('rates.composite').touched;
    }

    private _isAgeRatesValid(): boolean {
        return this.ratesForm.controls.effectiveDate.valid
            && this.ratesForm.controls.expirationDate.valid
            && this.ratesForm.get('rates.age').valid
            && this.ratesForm.get('rates.age').touched
            && this.ratesForm.get('age21Rate').valid
            && this.ratesForm.get('age21Rate').touched
            && (
                !this.ratesForm.get('hasTobaccoRates').value 
                || (this.ratesForm.get('rates.tobacco').valid
                    && this.ratesForm.get('rates.tobacco').touched
                ))
    }


    private _shouldCalculateRatesBeAnimated(): boolean {
        setTimeout(() => this.animateCalculateRates$.next(false), 1000);
        return this.ratesForm.controls.age21Rate.valid && !this.ratesForm.controls.hasTobaccoRates.value 
                || this.ratesForm.controls.age21Rate.valid && this.ratesForm.controls.hasTobaccoRates.value && this.ratesForm.controls.age21TobaccoRate.valid
    }

    private _ageRatesValidator(tobacco: boolean): ValidatorFn {
        return (group: FormGroup<RatesForm>): ValidationErrors => {
            if (group.root.get('rates.type')?.value !== RateType.AgeRate ||
                (tobacco && group.root.get('hasTobaccoRates')?.value === false)
            ) {
                return null;
            }
            const atLeastOneAgeRateIsZeroOrNull = Object.values(group.value).some((value: number) => !value || value <= 0);
            return atLeastOneAgeRateIsZeroOrNull ? { allRatesMustHaveValue: {} } : null;
        };
    }
}
