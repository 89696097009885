import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map } from "rxjs/operators";

import { AgeRateCurveResponse } from "../../interfaces";
import { AgeRateCurve } from "../models/create-plans.models";
import { Observable, of } from "rxjs";

@Injectable()
export class RatesService {

    constructor(private _http: HttpClient) {}
 
    getAgeRateCurve(zipCode: string): Observable<AgeRateCurve> {
        return this._http.get<AgeRateCurveResponse>('health-insurance-market/age-rate-curve/' + zipCode).pipe(
            catchError(() => of({ id: null, isFederalDefault: false, ageRateCurve: [] })),
            map((ageRateCurve) => {
            const rates: number[] = [];

            for (let i = 0; i <= 65; i++) {
                if (ageRateCurve['age' + i]) {
                    rates[i] = parseFloat(ageRateCurve['age' + i]);
                }
            }

            return ({
                id: ageRateCurve.id,
                isFederalDefault: ageRateCurve.isFederalDefault,
                ageRateCurve: rates,
            });
        })
        )
    }
}