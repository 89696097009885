import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
} from '@angular/router';
import { Observable } from 'rxjs';
import { EmployeeFacade } from './employee.facade';

@Injectable({
    providedIn: 'root'
})
export class EmployeeResolver implements Resolve<any> {
    constructor(private _employee: EmployeeFacade) {}

    resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
        return this._employee.get(route.paramMap.get('employeeId'));
    }
}
