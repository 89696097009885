import { Component, ViewChild } from '@angular/core';
import {
    NavigationCancel,
    NavigationEnd,
    NavigationStart,
    RouteConfigLoadEnd,
    RouteConfigLoadStart,
    Router,
    } from '@angular/router';
import { debounceTime } from 'rxjs/operators';

import { LoadingBarService } from '@ngx-loading-bar/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthFacadeService } from '@benefit-sculptor/auth';
import { HelpModalComponent } from '@besc/help';
import { WatchDemosIconComponent } from '@besc/onboarding';

@Component({
    selector: 'besc-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    @ViewChild(WatchDemosIconComponent) watchDemosIconsComponent: WatchDemosIconComponent;
    progress$ = this._loader.progress$;

    constructor(
        private _router: Router,
        private _loader: LoadingBarService,
        private _auth: AuthFacadeService,
        private _modal: NgbModal
    ) {
        // page progress bar percentage
        this._router.events.pipe(debounceTime(10)).subscribe((event) => {
            if (event instanceof NavigationStart) {
                // set page progress bar loading to start on NavigationStart event router
                this._loader.start();
            }
            if (event instanceof RouteConfigLoadStart) {
                this._loader.increment(35);
            }
            if (event instanceof RouteConfigLoadEnd) {
                this._loader.increment(75);
            }
            if (
                event instanceof NavigationEnd ||
                event instanceof NavigationCancel
            ) {
                // set page progress bar loading to end on NavigationEnd event router
                this._loader.complete();
            }
        });
    }

    logout() {
        this._auth.logout();
    }

    helpAndSupport(): void {
        const modalRef = this._modal.open(HelpModalComponent);
        modalRef.result.then(result => {
            if (result?.seeVideos) {
                this.watchDemosIconsComponent.presentOnboardingVideos();
            }
        });
    }
}
