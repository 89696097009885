import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { Agency, AgencyUser } from '../../agency.interface';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { AgencyService } from '../../services/agency.service';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatTable } from '@angular/material/table';
import { AddNewAgencyEmployeeDialogComponent } from './add-new-agency-employee-dialog/add-new-agency-employee-dialog.component';
import { DeleteAgencyUserDialogComponent } from './delete-agency-user-dialog/delete-agency-user-dialog.component';
import { BescValidators } from '@benefit-sculptor/core';
import { ActivatedRoute } from '@angular/router';
import { TransferOwnershipDialogComponent } from './transfer-ownership-dialog/transfer-ownership-dialog.component';

@Component({
    selector: 'besc-agency-info',
    templateUrl: './agency-info.component.html',
    styleUrls: ['./agency-info.component.scss'],
})
export class AgencyInfoComponent implements OnInit, OnChanges {
    private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    @Input() agency: Agency;

    @ViewChild(MatTable) table: MatTable<AgencyUser[]>;

    form: UntypedFormGroup;

    loading$ = this._loading.asObservable();

    constructor(
        private _fb: UntypedFormBuilder,
        private _agency: AgencyService,
        private _toastr: ToastrService,
        private _modal: NgbModal,
        private _route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this._configureForm();
        if (this._route.snapshot.queryParamMap.get('addEmployee')) {
            this.addAgencyEmployee();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.agency && !changes.agency.firstChange) {
            this._configureForm();
        }
    }

    updateAgency() {
        if (this.form.invalid) {
            return;
        }
        this._loading.next(true);
        const agency = this.form.value;
        delete agency['owner'];
        this._agency
            .update(this.agency.id, this.form.value)
            .pipe(finalize(() => this._loading.next(false)))
            .subscribe(
                () => {
                    this._toastr.success('Successfully updated the agency');
                },
                () => {
                    this._toastr.error('Failed to update the agency');
                }
            );
    }

    addAgencyEmployee() {
        const modal = this._modal.open(AddNewAgencyEmployeeDialogComponent);
        const instance = modal.componentInstance as AddNewAgencyEmployeeDialogComponent;
        instance.agency = this.agency;

        modal.result.then(
            (newUser) => {
                this.agency.employees.push(newUser);
                this.table.renderRows();
                this._toastr.success('User was added successfully.');
            },
            () => {}
        );
    }

    deleteAgencyEmployee(employee: AgencyUser) {
        const modal = this._modal.open(DeleteAgencyUserDialogComponent);
        const instance = modal.componentInstance as DeleteAgencyUserDialogComponent;
        instance.user = employee;

        modal.result.then(
            () => {
                this.agency.employees = this.agency.employees.filter((user) => {
                    return employee.id !== user.id;
                });
                this.table.renderRows();
                this._toastr.success('User was removed successfully.');
            },
            () => {}
        );
    }

    transferOwnership(employee: AgencyUser) {
        const modal = this._modal.open(TransferOwnershipDialogComponent);
        const instance = modal.componentInstance as TransferOwnershipDialogComponent;
        instance.newOwner = employee;
        instance.agency = this.agency;

        modal.result.then(
            (updatedAgency) => {
                this.agency = updatedAgency;
                this._configureForm();
            },
            () => {}
        );
    }

    private _configureForm() {
        this.form = this._fb.group({
            name: [this.agency?.name, Validators.required],
            contactInfo: this._fb.group({
                address: [this.agency?.contactInfo.address],
                city: [this.agency?.contactInfo.city],
                zipCode: [
                    this.agency?.contactInfo.zipCode,
                    BescValidators.requiredZipCode,
                ],
            }),
            owner: this._fb.group({
                id: [this.agency?.owner?.id],
                firstName: [this.agency?.owner?.firstName],
                lastName: [this.agency?.owner?.lastName],
                email: [this.agency?.owner?.email, Validators.email],
                isActive: [this.agency?.owner?.isActive],
            }),
        });

        this.form.get('owner').disable();
    }
}
