import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { HeaderNavigationComponent } from './header-navigation.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NavigationTitleComponent } from './navigation-title/navigation-title.component';

@NgModule({
    declarations: [HeaderNavigationComponent, NavigationTitleComponent],
    exports: [HeaderNavigationComponent],
    imports: [
        CommonModule,
        FontAwesomeModule,
        RouterModule,
        NgbDropdownModule,
    ],
})
export class NavigationModule {}
