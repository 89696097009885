<div class="modal-header">
    <h4 class="modal-title">
        Bulk Change plans for employees
        <p>
            {{ employer.companyName }}
        </p>
    </h4>
    <button
        type="button"
        class="btn btn-outline-light fs-3 btn-sm"
        aria-label="Close"
        (click)="close()"
    >
        <fa-icon [icon]="['fad', 'times']"></fa-icon>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="currentPlanForm" *ngIf="{ value: currentPlanBS$ | async } as currentPlanBS">
        <ng-container *ngIf="!currentPlan; else presetPlanTemplate">
            <div class="d-flex justify-content-between">
                <div class="card w-49">
                    <label class="form-label">
                        Find employees & plans as of:*
                    </label>
                    <besc-month-year-picker
                        formControlName="dateFilter"
                        placeholder="Find employees & plans as of:*"
                        bescEffectiveDate
                        [readonly]="true"
                    ></besc-month-year-picker>
                </div>
                <div class="card w-49">
                    <label class="form-label">
                        Changes effective as of:*
                    </label>
                    <besc-month-year-picker
                        *ngIf="currentPlanBS$.value"
                        formControlName="dateApplied"
                        placeholder="Changes effective as of:*"
                        bescEffectiveDate
                        [readonly]="true"
                        [max]="currentPlanBS.value?.terminationDate"
                        [min]="currentPlanBS.value?.effectiveDate"
                        [formatMMYYYY]="true"
                    ></besc-month-year-picker>
                </div>
            </div>
            <div class="card mt-4">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="w-50">
                        <label for="currentPlan" class="form-label">
                            Plans*
                        </label>
                        <select
                            id="currentPlan"
                            name="currentPlan"
                            class="form-select"
                            formControlName="currentPlan"
                        >
                            <option [value]="'all'">Please Select a Plan</option>
                            <option
                                *ngFor="let employerPlan of employerPlans$ | async"
                                [value]="employerPlan.id"
                            >
                                {{ employerPlan.planGenericName }}
                            </option>
                        </select>
                        <p *ngIf="currentPlanForm.value.currentPlan === 'all'" class="form-label mt-2">
                            To make changes for an employee, please select a plan from the list of
                            current plans
                        </p>
                    </div>
                    <div class="w-49" *ngIf="currentPlanBS.value">
                        <p class="mb-1">
                            <strong>Plan effective:</strong> 
                            {{ currentPlanBS.value.effectiveDate | date: 'shortDate' }} - {{ currentPlanBS.value.terminationDate | date: 'shortDate' }}
                        </p>
                        <a (click)="displayPlanDetails()">View Plan details</a>
                    </div>
                </div>
            </div>
        </ng-container>
        
        <ng-template #presetPlanTemplate>
            <div class="d-flex justify-content-between">
                <div class="card w-49">
                    <label class="form-label">
                        Changes effective as of:*
                    </label>
                    <besc-month-year-picker
                        [hidden]="!currentPlanBS$.value"
                        formControlName="dateApplied"
                        placeholder="Changes effective as of:*"
                        bescEffectiveDate
                        [readonly]="true"
                        [max]="currentPlanBS.value?.terminationDate"
                        [min]="currentPlanBS.value?.effectiveDate"
                    ></besc-month-year-picker>
                </div>
                <div class="card w-49">
                    <h4><strong>{{ currentPlan.planGenericName }}</strong></h4>
                    <p class="mb-1">
                        Plan effective:
                        {{ currentPlan.effectiveDate | date: 'shortDate' }} - {{ currentPlan.terminationDate | date: 'shortDate' }}
                    </p>
                </div>
            </div>
        </ng-template>
    
        <div class="employee-lists mt-4">
            <div class="loading" *ngIf="loading$ | async">
                <mat-spinner [diameter]="40" color="primary"></mat-spinner>
            </div>
            <div class="card">
                <besc-employee-list
                    [disabled]="currentPlanForm.value.currentPlan === 'all'"
                    [connectedTo]="['employees-on-plan']"
                    [employees$]="employeesOffPlan$"
                    uniqueId="employees-off-plan"
                    (drag)="moveEmployee($event)"
                    (clickItem)="moveToOtherList($event)"
                    listTitle="Unassigned Employees"
                    [employeePlans]="employeePlans$ | async"
                    [showDates]="false"
                ></besc-employee-list>
            </div>
    
            <div class="card">
                <besc-employee-list
                    [disabled]="currentPlanForm.value.currentPlan === 'all'"
                    [connectedTo]="['employees-off-plan']"
                    [employees$]="employeesOnPlan$"
                    uniqueId="employees-on-plan"
                    (drag)="moveEmployee($event)"
                    (clickItem)="moveToOtherList($event)"
                    listTitle="Assigned Employees"
                    [employeePlans]="employeePlans$ | async"
                    [showDates]="true"
                ></besc-employee-list>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <p>
        For individual employee plan assignments, navigate to 
        <a (click)="navigateToEmployees()">employees</a>   
        and click on an employee name.
    </p>
    <div class="d-flex">
        <button
            *ngIf="(loading$ | async) !== true"
            [disabled]="(hasChanges$ | async) !== true"
            type="button"
            style="margin-right: 24px;"
            class="bs-button bs-secondary mr-2"
            (click)="reset()"
        >
            Reset
        </button>
        <button
            *ngIf="(loading$ | async) !== true"
            [disabled]="(hasChanges$ | async) !== true"
            type="button"
            class="bs-button bs-primary"
            (click)="setChanges(); attachEmployeesToPlan()"
        >
            Save
        </button>
    </div>

    <mat-spinner
        *ngIf="loading$ | async"
        [diameter]="24"
        color="primary"
    ></mat-spinner>
</div>
