/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CoreModule } from '@benefit-sculptor/core';
import {
    IsAdminGuard,
    IsStaffGuard,
    IsAuthenticatedGuard,
    IsUnauthenticatedGuard,
    NotAdminGuard,
    UserTypeGuard,
    RoleType,
} from '@benefit-sculptor/auth';
import { AgencyIsActiveGuard } from '@besc/agency';
import { AdminDashboardComponent } from './home/pages/admin-dashboard/admin-dashboard.component';
import { SmallEmployerNavResolver } from './small-employers/services/small-employer-nav.resolver';
import { BaseLayoutComponent } from '@besc/layout';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: '/app/home',
    },
    {
        path: 'auth',
        canActivateChild: [IsUnauthenticatedGuard],
        data: {
            redirectIfAuth: ['/app/home'],
        },
        loadChildren: () =>
            import('./auth/auth.module').then((m) => m.AuthModule),
    },
    {
        path: 'auth/register',
        canActivateChild: [IsAuthenticatedGuard, IsStaffGuard],
        data: {
            redirectIfNoAuth: ['/auth/login'],
            redirectIfNotStaff: ['/app/home'],
        },
        loadChildren: () =>
            import('./registration/registration.module').then(
                (m) => m.RegistrationModule
            ),
    },
    {
        path: 'app/admin',
        canActivateChild: [IsAdminGuard, IsAuthenticatedGuard],
        data: {
            redirectIfNotAdmin: ['/app/small-employers/list'],
        },
        loadChildren: () =>
            import('./admin/admin.module').then((m) => m.AdminModule),
    },
    {
        path: 'app/account',
        canActivateChild: [NotAdminGuard, IsAuthenticatedGuard],
        data: {
            redirectIfAdmin: ['/app/admin/agencies/list'],
        },
        loadChildren: () =>
            import('./account/account.module').then((m) => m.AccountModule),
    },
    {
        path: 'app',
        canActivateChild: [
            IsAuthenticatedGuard,
            NotAdminGuard,
            AgencyIsActiveGuard,
        ],
        data: {
            redirectIfNoAuth: ['/auth/login'],
            redirectIfAdmin: ['/app/admin/agencies/list'],
        },
        children: [
            {
                path: 'home',
                loadChildren: () =>
                    import('./home/home.module').then((m) => m.HomeModule),
            },
            {
                path: 'admin-center',
                resolve: {
                    navigation: SmallEmployerNavResolver,
                },
                component: BaseLayoutComponent,
                children: [
                    {
                        path: '',
                        component: AdminDashboardComponent,
                    },
                ],
            },
            {
                path: 'account-settings',
                resolve: {
                    navigation: SmallEmployerNavResolver,
                },
                component: BaseLayoutComponent,
                loadChildren: () =>
                    import('@besc/settings').then((m) => m.SettingsModule),
            },
            {
                path: 'small-employers',
                loadChildren: () =>
                    import('./small-employers/small-employers.module').then(
                        (m) => m.SmallEmployersModule
                    ),
            },
            {
                path: 'agency',
                loadChildren: () =>
                    import('./agency/agency.module').then(
                        (m) => m.AgencyModule
                    ),
            },
            {
                path: 'manage',
                loadChildren: () =>
                    import('./admin/admin.module').then((m) => m.AdminModule),
            },
            {
                path: 'billing',
                canActivate: [UserTypeGuard],
                data: {
                    userType: RoleType.ADMIN_AGENT,
                },
                loadChildren: () =>
                    import('@besc/billing').then((m) => m.BillingModule),
            },
        ],
    },
];

@NgModule({
    declarations: [],
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
        CoreModule,
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
