import { InjectionToken, Type } from '@angular/core';
import { MemoizedSelector } from '@ngrx/store';

export const wizaredFeatureKey = 'wizard';

export const WIZARD_ACTIONS = new InjectionToken('Wizard Feature');
export const WIZARD_SELECTORS = new InjectionToken('Wizard Selectors');

export interface WizardActions {
    finishWizard: any;
}

export interface WizardSelectors {
    selectWizardFeature: MemoizedSelector<any, WizardState>;
    getStepRouting: MemoizedSelector<any, StepRouteDefinition>;
    getPlanSteps: MemoizedSelector<any, Steps>;
    getStepRoute: MemoizedSelector<any, StepRoute>;
    getCurrentStepPath: MemoizedSelector<any, string[]>;
    getCurrentValue: MemoizedSelector<any, any>;
    getActivatedStep: MemoizedSelector<any, ActivatedStep>;
    getSteps: MemoizedSelector<any, Step[]>;
    getNavigating: MemoizedSelector<any, boolean>;
}

export interface WizardState {
    pathToCurrentStep: string[];
    navigating: boolean;
    stepRouting: StepRouteDefinition;
    value: { [key: string]: any };
    steps: Steps;
    saving: boolean;
}

export interface Step {
    label?: string;
    icon?: [string, string];
    component?: Type<any>;
    default?: boolean;
    current?: boolean;
    placeholder?: boolean;
    future?: boolean;
    complete?: boolean;
    selected?: boolean;
    navigable?: boolean;
    pathFromRoot?: string[];
    id?: string;
}

export interface Steps {
    [key: string]: Step;
}

export interface FutureStep extends Step {
    label: string;
    icon: [string, string];
    current: false;
    placeholder: false;
    future: true;
    complete: boolean;
    pathFromRoot: string[];
}

export interface PlaceholderStep extends Step {
    placeholder: true;
    current: false;
    future: false;
    complete: false;
}

export interface CurrentStep extends Step {
    label: string;
    icon: [string, string];
    current: true;
    future: false;
    placeholder: false;
    complete: boolean;
}

export interface ParentStep extends Step {
    label: string;
    icon: [string, string];
    current: false;
    future: false;
    complete: true;
    pathFromRoot: string[];
}

export type ChildStep = ParentStep;

export interface StepRouteChild {
    condition: (stepValue: any) => boolean;
    route: StepRoute;
}

export interface StepRoute {
    step: Step;
    key: string;
    parent: StepRoute;
    children: { [key: string]: StepRouteChild };
    root: StepRoute;
    totalPossibleSteps: number;
    pathFromRoot: string[];
    condition?: (stepValue: any) => boolean;
}

export interface StepRouteDefinition {
    step: string;
    children?: {
        route: StepRouteDefinition;
        condition?: (stepValue: any) => boolean;
    }[];
    child?: StepRouteDefinition;
}

export interface ActivatedStep {
    firstChild: ActivatedStep | null;
    step: Step;
    key: string;
    root: ActivatedStep;
    parent: ActivatedStep;
    route: StepRoute;
    value: any;
}
