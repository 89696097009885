import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { Navigation } from '@benefit-sculptor/navigation';
import { RouteParserService } from '@benefit-sculptor/router';
import { AgencyService, AgencyStatus } from '@besc/agency';

@Injectable({
  providedIn: 'root'
})
export class EmployerNavResolver {

  constructor(
    private _routeParser: RouteParserService,
    private _agency: AgencyService
  ) { }

  resolve(route: ActivatedRouteSnapshot):
    | Observable<Navigation | Observable<Navigation>>
    | Promise<Navigation | Observable<Navigation>>
    | Navigation
    | Observable<Navigation> {
    const params = this._routeParser.getParamsFromRoute(route);
    return this._agency.userAgency$.pipe(
      map((agency) => {
        return {
          items: [
            {
              label: 'Employers',
              icon: ['fad', 'list'],
              disabled: agency.status !== AgencyStatus.ACTIVE,
              link: ['/app/small-employers/list'],
              notExact: true,
              separator: true,
            },
            {
              label: 'Employees',
              icon: ['fad', 'users'],
              disabled: typeof params.employerId === 'undefined',
              notExact: true,
              link: [
                '/app/small-employers/:employerId/employees',
              ],
            },
            {
              label: 'Plans',
              icon: ['fad', 'id-card'],
              notExact: true,
              disabled: typeof params.employerId === 'undefined',
              link: ['/app/small-employers/:employerId/plans'],
            },
          ],
        } as Navigation;
      }),
      first()
    );
  }

}
