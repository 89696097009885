import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputValidationDirective } from './input-validation.directive';
import { PhoneNumberInputDirective } from './phone-number-input.directive';
import { NumericInputDirective } from './numeric-input.directive';

@NgModule({
    declarations: [		
        InputValidationDirective,
        PhoneNumberInputDirective,
        NumericInputDirective,
   ],
    imports: [CommonModule],
    exports: [
        InputValidationDirective,
        PhoneNumberInputDirective,
        NumericInputDirective,
    ],
})
export class FormHelpersModule {}
