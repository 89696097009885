import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './pages/login/login.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthComponent } from './components/auth/auth.component';
import { RegisterComponent } from './pages/register/register.component';
import { CheckExistingEmailValidator } from './pages/register/check-existing-email.validator';
import { VerifyComponent } from './pages/verify/verify.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { PasswordComplexityComponent } from './components/password-complexity/password-complexity.component';
import { PasswordComplexityValidator } from './components/password-complexity/password-complexity.validator';
import { SpinnerModule } from '@benefit-sculptor/spinner';
import { ChevronLeftIconComponent, FormHelpersModule } from '@benefit-sculptor/core';
import { SetPasswordComponent } from './pages/set-password/set-password.component';
import { RegistrationContainerComponent } from '@besc/registration';
import { ForgotPasswordSuccessComponent } from './pages/forgot-password-success/forgot-password-success.component';

@NgModule({
    declarations: [
        PasswordComplexityValidator,
        LoginComponent,
        AuthComponent,
        RegisterComponent,
        CheckExistingEmailValidator,
        VerifyComponent,
        ForgotPasswordComponent,
        PasswordComplexityComponent,
        SetPasswordComponent,
        ForgotPasswordSuccessComponent,
    ],
    imports: [
        CommonModule,
        AuthRoutingModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        SpinnerModule,
        FormHelpersModule,
        RegistrationContainerComponent,
        ChevronLeftIconComponent,
    ],
    exports: [CheckExistingEmailValidator],
})
export class AuthModule {}
