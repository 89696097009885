import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HeaderService } from '../../header/header.service';

@Component({
  selector: 'besc-navigation-title',
  templateUrl: './navigation-title.component.html',
  styleUrls: ['./navigation-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationTitleComponent {

  title$ = this._header.navigationTitle$;

  constructor(
    private _header: HeaderService
  ) { }

}
